'use client';

import { getRouterPath } from '@/components';
import webViewService from '@/lib/webViewService';
import { usePathname, useRouter } from 'next/navigation';
import { useModal } from './useModal';

export const useLoginInducingModal = () => {
  const { confirm } = useModal();
  const router = useRouter();
  const pathname = usePathname();

  const loginConfirm = (redirectPathname?: string) => {
    confirm({
      title: '로그인 안내',
      description: (
        <>
          로그인 후 이용할 수 있습니다. <br /> 로그인하러 가시겠습니까?
        </>
      ),
      confirmText: '로그인',
      onConfirm: () => {
        const routerPath = getRouterPath({
          isAppWebView: webViewService.isAppWebView(window.navigator.userAgent),
        });

        router.push(routerPath.loginRedirect(redirectPathname ?? pathname));
      },
      hasCancel: true,
    });
  };

  return loginConfirm;
};
