'use client';

import { getRouterPath } from '@/components';
import { useHomeFollowingChannelsQuery } from '@/entities';
import { ChannelItem } from '@/entities/channel/components/ChannelItem';
import { Column, Divider, Element, Icon, Row } from '@vuddy/components';
import { typography } from '@vuddy/components/theme';
import Link from 'next/link';
import * as styles from './myChannels.css';

export const HomeMyChannels = () => {
  const routerPath = getRouterPath({
    isAppWebView: false,
  });
  const { data: followingChannelsData } = useHomeFollowingChannelsQuery();
  const followingChannels = followingChannelsData?.data ?? [];

  return followingChannels.length > 0 ? (
    <>
      <Column width="100%" gap="gap_2xl">
        <Row gap={'gap_xs'} paddingX="image_2xs">
          <Icon
            name="FILL_VERIFIED_24"
            color="primary"
            width="20px"
            height="20px"
          />
          <div className={typography.bodyBaseAccent}>마이 크리에이터</div>
        </Row>
        <Row
          gap="gap_base"
          className={styles.hideScrollbar}
          paddingX="padding_small">
          {followingChannels.map(followingChannel => (
            <div
              key={followingChannel.channel.id}
              className={styles.channelWrapper}>
              <Link
                href={routerPath.channelShop({
                  channelUrl: followingChannel.channel.url,
                })}>
                <ChannelItem
                  channel={followingChannel.channel}
                  padding="0 8px"
                />
              </Link>
              {followingChannel.hasRedDot ? (
                <div className={styles.redDot} />
              ) : null}
            </div>
          ))}
        </Row>
      </Column>
      <Element width="100%" paddingX="padding_small">
        <Divider color="dividerPrimary" marginY="gap_4xl" />
      </Element>
    </>
  ) : null;
};
