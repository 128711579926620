import { request } from '@/lib/request';
import { useMutation, useQuery } from '@/lib/useWrappingQuery';
import {
  IOrderRequestApiRequest,
  IOrderRequestApiResponse,
  IOrderVerifyRequest,
  IOrderVerifyResponse,
} from '@/types/order';
import {
  IInappPurchasePaymentRequest,
  IKcpPurchasePaymentRequest,
  IPaymentResponse,
} from '@/types/payment';
import { orderResultQueryOption } from './options';

const orderVerify = (params: IOrderVerifyRequest) => {
  return request.post<IOrderVerifyResponse>('/orders/verify', params);
};

export const useOrderVerify = (options?: {
  onSuccess?: (data: IOrderVerifyResponse) => void;
  onError?: (error: Error) => void;
}) => {
  return useMutation({
    mutationFn: orderVerify,
    onSuccess: data => {
      options?.onSuccess?.(data.data);
    },
    onError: error => {
      options?.onError?.(error);
    },
  });
};

const orderRequest = (params: IOrderRequestApiRequest) => {
  return request.post<IOrderRequestApiResponse>('/orders/request', params);
};

export const useOrderRequest = (options?: {
  onError?: (error: Error) => void;
}) => {
  return useMutation({
    mutationFn: orderRequest,
    skipDefaultErrorHandling: () => true,
    onError: error => {
      options?.onError?.(error);
    },
  });
};

const orderTermsAgree = () => {
  return request.post<boolean>('/orders/terms/agree');
};

export const useOrderTermsAgree = () => {
  return useMutation({
    mutationFn: orderTermsAgree,
  });
};

const cancelOrderRequest = (traceId: string) => {
  return request.post<boolean>(`/orders/traces/${traceId}/cancel`);
};

export const useCancelOrderRequest = (options?: { onSuccess?: () => void }) => {
  return useMutation({
    mutationFn: cancelOrderRequest,
    onSuccess: data => {
      options?.onSuccess?.();
    },
  });
};

const kcpPurchase = (params: IKcpPurchasePaymentRequest) => {
  return request.post<IPaymentResponse>('/payments/kcp-purchase', params);
};

export const useKcpPurchase = (options?: {
  onError?: (error: Error, traceId: string) => void;
}) => {
  return useMutation({
    mutationFn: kcpPurchase,
    skipDefaultErrorHandling: () => true,
    onError: (error, variables) => {
      options?.onError?.(error, variables.traceId);
    },
  });
};

const inAppPurchase = (params: IInappPurchasePaymentRequest) => {
  return request.post<IPaymentResponse>('/payments/inapp-purchase', params);
};

export const useInAppPurchase = (options?: {
  onError?: (error: Error) => void;
}) => {
  return useMutation({
    mutationFn: inAppPurchase,
    skipDefaultErrorHandling: () => true,
    onError: error => {
      options?.onError?.(error);
    },
  });
};

interface useOrderResultPollingQueryProps {
  traceId: string;
  refetchInterval?: number;
  shouldPoll: boolean;
}

export const useOrderResultPollingQuery = ({
  traceId,
  refetchInterval,
  shouldPoll,
}: useOrderResultPollingQueryProps) => {
  return useQuery({
    ...orderResultQueryOption({ pathVariables: traceId }),
    refetchInterval: shouldPoll ? refetchInterval ?? 1000 : false,
    enabled: !!traceId,
    skipDefaultErrorHandling: true,
  });
};
