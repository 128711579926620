import { request } from '@/lib/request';
import { useMutation, useQuery } from '@/lib/useWrappingQuery';
import {
  IRegisteredUserResponse,
  ISocialUserSignUpRequest,
  IUserAgreementRequest,
  TermsWithAgreementType,
} from '@/types';
import { isNonNil } from '@vuddy/utils';
import { agreedTermsQueryOptions, signupTermsQueryOptions } from './options';

export const useSignupTerms = () => {
  return useQuery(signupTermsQueryOptions.list());
};

/* 가입 */
const signupUser = (params: ISocialUserSignUpRequest) => {
  return request.post<IRegisteredUserResponse>('/users/sign-up', params);
};

export const useSignupUser = () => {
  return useMutation({
    mutationFn: signupUser,
    onSuccess: response => {
      if (isNonNil(response.error)) {
        console.error(response.error);
      }
    },
  });
};

export const useAgreedTerms = (type?: TermsWithAgreementType) => {
  return useQuery(
    agreedTermsQueryOptions.list({
      payload: {
        type,
      },
    })
  );
};

const agreeTerms = (params: IUserAgreementRequest) => {
  return request.post<boolean>('/terms/sign-up/agree', params);
};

export const useAgreeTerms = ({ onSuccess }: { onSuccess: VoidFunction }) => {
  return useMutation({
    mutationFn: agreeTerms,
    onSuccess: response => {
      if (isNonNil(response.error)) {
        console.error(response.error);
      } else {
        onSuccess();
      }
    },
  });
};
