import { request } from '@/lib/request';
import { IPaginatedRequest } from '@/types';
import {
  ICursoredResponseHomeChannelSearchResponse,
  IHomeFollowingChannelResponse,
  IHomeHeadlineResponse,
  IHomeNoticeResponse,
} from '@/types/home';
import { createQueryOption } from 'react-query-key-helper';

export const homeNoticesQueryOptions = {
  list: createQueryOption({
    apiUrl: () => '/home/notices',
    queryFn: (apiUrl: string) => request.get<IHomeNoticeResponse[]>(apiUrl),
  }),
};

export const homeHeadlinesQueryOptions = {
  list: createQueryOption({
    apiUrl: () => '/home/headlines',
    queryFn: (apiUrl: string) => request.get<IHomeHeadlineResponse[]>(apiUrl),
  }),
};

export const homeFollowingChannelsQueryOptions = {
  list: createQueryOption({
    apiUrl: () => '/home/channels/following',
    queryFn: (apiUrl: string) =>
      request.get<IHomeFollowingChannelResponse[]>(apiUrl),
  }),
};

export const homeChannelsQueryOptions = {
  list: createQueryOption({
    apiUrl: () => '/home/channels/_search',
    queryFn: (apiUrl: string, payload: IPaginatedRequest) =>
      request.post<ICursoredResponseHomeChannelSearchResponse>(apiUrl, payload),
  }),
};
