import { request } from '@/lib/request';
import { useMutation, useQuery } from '@/lib/useWrappingQuery';
import {
  IDeliveryAddressResponse,
  IDeliveryAddressUpsertRequest,
} from '@/types';
import { isNonNil } from '@vuddy/utils';
import { deliveryQueryOptions } from './options';

export const useDeliveryAddressListQuery = (params?: { enabled?: boolean }) => {
  return useQuery({
    ...deliveryQueryOptions.list(),
    enabled: params?.enabled ?? true,
    retry: false,
  });
};

export const useDeliveryAddressQuery = (deliveryId?: number) => {
  return useQuery({
    ...deliveryQueryOptions.one({ pathVariables: deliveryId }),
    enabled: !!deliveryId,
  });
};

const createDeliveryAddress = (params: IDeliveryAddressUpsertRequest) => {
  return request.post<IDeliveryAddressResponse>(
    '/users/delivery-address',
    params
  );
};

export const useCreateDeliveryAddressQuery = ({
  onSuccess,
}: {
  onSuccess: VoidFunction;
}) => {
  return useMutation({
    mutationFn: createDeliveryAddress,
    onSuccess: response => {
      if (isNonNil(response.error)) {
        console.error(response.error);
      } else {
        onSuccess();
      }
    },
  });
};

const updateDeliveryAddressQuery = ({
  deliveryId,
  ...params
}: IDeliveryAddressUpsertRequest & {
  deliveryId: number;
}) => {
  return request.patch<boolean>(
    `/users/delivery-address/${deliveryId}`,
    params
  );
};

export const useUpdateDeliveryAddressQuery = ({
  onSuccess,
}: {
  onSuccess: VoidFunction;
}) => {
  return useMutation({
    mutationFn: updateDeliveryAddressQuery,
    onSuccess: response => {
      if (isNonNil(response.error)) {
        console.error(response.error);
      } else {
        onSuccess();
      }
    },
  });
};

const deleteUsersDeliveryAddress = (deliveryAddressId: number) => {
  return request.delete<boolean>(
    `/users/delivery-address/${deliveryAddressId}`
  );
};

export const useDeleteDeliveryAddressQuery = ({
  onSuccess,
}: {
  onSuccess: VoidFunction;
}) => {
  return useMutation({
    mutationFn: deleteUsersDeliveryAddress,
    onSuccess: response => {
      if (isNonNil(response.error)) {
        console.error(response.error);
      } else {
        onSuccess();
      }
    },
  });
};
