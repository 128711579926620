'use client';

import { IHomeChannelResponse } from '@/types/home';
import { ClearButton, Column, Row } from '@vuddy/components';
import * as styles from './channelCardItem.css';
import { ChannelItem } from './ChannelItem';

interface ChannelCardItemProps {
  channel: IHomeChannelResponse;
  isNewbie: boolean;
  isSelectedItem?: boolean;
  onClick?: (creatorUrl: string) => void;
}

export const ChannelCardItem = ({
  channel,
  isNewbie,
  isSelectedItem = false,
  onClick,
}: ChannelCardItemProps) => {
  return (
    <ClearButton
      fullWidth
      onClick={() => {
        onClick?.(channel.url);
      }}>
      <Column
        className={styles.wrapper}
        height={184}
        justifyContent="center"
        borderRadius="radius_large"
        borderColor="vuddyPrimary"
        borderStyle="solid"
        borderWidth={isSelectedItem ? 'stroke_xl' : 0}
        backgroundColor={isSelectedItem ? 'vuddyQuaternary' : 'primary'}>
        {isNewbie ? (
          <Row className={styles.badge} alignItems="center">
            NEW
          </Row>
        ) : null}
        <ChannelItem
          channel={channel}
          gap="gap_base"
          imageSize="image_2xl"
          nameLineClamp={2}
          othersLineClamp={1}
          showTags
        />
      </Column>
    </ClearButton>
  );
};
