import { request } from '@/lib/request';
import {
  ITermsResponse,
  ITermsWithAgreementResponse,
  TermsWithAgreementType,
} from '@/types';
import { createQueryOption } from 'react-query-key-helper';

export const signupTermsQueryOptions = {
  list: createQueryOption({
    apiUrl: () => '/terms/sign-up',
    queryFn: (apiUrl: string) => request.get<ITermsResponse[]>(apiUrl),
  }),
};

export const agreedTermsQueryOptions = {
  list: createQueryOption({
    apiUrl: () => '/terms/sign-up/agreed',
    queryFn: (
      apiUrl: string,
      payload: {
        type?: TermsWithAgreementType;
      }
    ) =>
      request.get<ITermsWithAgreementResponse[]>(apiUrl, {
        params: payload,
      }),
  }),
};
