import { request } from '@/lib/request';
import { useInfiniteQuery, useQuery } from '@/lib/useWrappingQuery';
import {
  IOnBoardingChannelResponse,
  IPaginatedRequest,
  IPaginatedResponse,
  ISalePageSearchRequest,
  SortType,
} from '@/types';
import { isNonNil } from '@vuddy/utils';
import { isNaN } from 'lodash-es';
import {
  channelDetailsByIdQueryOption,
  channelDetailsQueryOption,
  channelSalePageQueryOptions,
  onBoardingChannelsQueryOptions,
} from './options';

const searchOnBoardingChannels = (params: IPaginatedRequest) => {
  return request.post<IPaginatedResponse<IOnBoardingChannelResponse>>(
    '/onboarding/channels/_search',
    params
  );
};

export const onBoardingChannelsQueryPalyload = {
  sortType: 'DISPLAY_SCORE_DESC' as SortType,
  size: 10,
};

export const useOnBoardingChannelsQuery = () => {
  const queryOptions = onBoardingChannelsQueryOptions.list({
    payload: onBoardingChannelsQueryPalyload,
  });

  return useInfiniteQuery({
    queryKey: queryOptions.queryKey,
    queryFn: ({ pageParam: cursor }) => {
      return searchOnBoardingChannels({
        ...onBoardingChannelsQueryPalyload,
        cursor,
      });
    },
  });
};

export const useChannelDetailQuery = (url: string) => {
  return useQuery(channelDetailsQueryOption({ payload: { url } }));
};

export const useChannelDetailByIdQuery = (channelId?: number) => {
  return useQuery({
    ...channelDetailsByIdQueryOption({ pathVariables: channelId }),
    enabled: isNonNil(channelId) && !isNaN(channelId),
  });
};

export const useChannelSaleList = (
  channelId: number,
  params: ISalePageSearchRequest
) => {
  const queryOption = channelSalePageQueryOptions.list({
    payload: params,
    pathVariables: channelId,
  });

  return useInfiniteQuery({
    queryKey: queryOption.queryKey,
    queryFn: ({ pageParam: cursor }) =>
      channelSalePageQueryOptions
        .list({
          payload: { ...params, cursor },
          pathVariables: channelId,
        })
        .queryFn(),
  });
};
