'use client';

import { useIntersectionObserver } from '@/hooks/useIntersectionObserver';
import { ClearButton, Icon } from '@vuddy/components';
import { jsonAssignInlineVars } from '@vuddy/utils';
import { useRef, useState } from 'react';
import * as styles from './topArea.css';

export const HomeTopArea = () => {
  const topAreaRef = useRef(null);
  const [isShow, setIsShow] = useState(false);

  useIntersectionObserver(
    topAreaRef,
    ({ isOutside }) => {
      setIsShow(isOutside);
    },
    {
      rootMargin: '5%',
    }
  );

  const onClickToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <div ref={topAreaRef} className={styles.topArea} />
      <ClearButton
        className={styles.arrow}
        style={jsonAssignInlineVars({
          [styles.display]: isShow ? 'block' : 'none',
        })}
        onClick={onClickToTop}>
        <Icon name="OUTLINE_ARROW_UP_24" />
      </ClearButton>
    </>
  );
};
