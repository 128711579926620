'use client';

import { getRouterPath } from '@/components';
import { useHomeChannelsQuery } from '@/entities';
import { ChannelGrid } from '@/entities/channel/components/ChannelGrid';
import { useIntersectionObserver } from '@/hooks/useIntersectionObserver';
import { Column } from '@vuddy/components';
import { typography } from '@vuddy/components/theme';
import { useRouter } from 'next/navigation';
import { useEffect, useRef, useState } from 'react';

export const HomeAllChannels = () => {
  const router = useRouter();
  const routerPath = getRouterPath({
    isAppWebView: false,
  });
  const loadingRef = useRef<HTMLDivElement>(null);
  const [showLoadingObserver, setShowLoadingObserver] = useState(false);
  const {
    data: channelsData,
    isSuccess,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useHomeChannelsQuery();

  useEffect(() => {
    setShowLoadingObserver(hasNextPage ?? false);
  }, [channelsData]);

  useIntersectionObserver(loadingRef, ({ isOutside }) => {
    if (!hasNextPage || isOutside || isFetchingNextPage) return;
    fetchNextPage();
  });

  return (channelsData?.pages ?? []).length > 0 ? (
    <Column gap="gap_2xl" width="100%" paddingX="padding_small">
      <div className={typography.bodyBaseAccent}>전체 크리에이터</div>
      <ChannelGrid
        channels={channelsData?.pages ?? []}
        showLoadingObserver={showLoadingObserver}
        onClickItem={url =>
          router.push(routerPath.channelShop({ channelUrl: url }))
        }
        ref={loadingRef}
      />
    </Column>
  ) : null;
};
