import { request } from '@/lib/request';
import {
  INotificationChannelResponse,
  INotificationRequest,
  INotificationResponse,
  IPaginatedResponse,
} from '@/types';
import { createQueryOption } from 'react-query-key-helper';

export const notificationChannelsQueryOptions = {
  list: createQueryOption({
    apiUrl: () => '/users/notifications/channels',
    queryFn: (apiUrl: string) =>
      request.get<INotificationChannelResponse[]>(apiUrl),
  }),
};

export const notificationsQueryOptions = {
  list: createQueryOption({
    apiUrl: () => '/users/notifications',
    queryFn: (apiUrl: string, payload: INotificationRequest) =>
      request.get<IPaginatedResponse<INotificationResponse>>(apiUrl, {
        params: payload,
      }),
  }),
};
