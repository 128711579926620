import { request } from '@/lib/request';
import { useMutation } from '@/lib/useWrappingQuery';
import {
  ApiResponseT,
  IErrorResponse,
  IMediaFileResponse,
  IRegisteredUserResponse,
  IUserLeaveRequest,
  IUsernameVerifyRequest,
  IUserUpdateRequest,
} from '@/types';
import { removeToken } from '@/utils/authToken';
import { isNonNil } from '@vuddy/utils';

const updateUserProfile = (params: IUserUpdateRequest) => {
  return request.patch<IRegisteredUserResponse>('/users', params);
};

export const useUpdateUserProfile = ({
  onSuccess,
}: {
  onSuccess: VoidFunction;
}) => {
  return useMutation({
    mutationFn: updateUserProfile,
    onSuccess: response => {
      if (isNonNil(response.error)) {
        console.error(response.error);
      }
      onSuccess();
    },
  });
};

const generateUserName = () => {
  return request.get<string>('/users/generate-username');
};

export const useGenerateUserName = () => {
  return useMutation({
    mutationFn: generateUserName,
    onSuccess: response => {
      if (isNonNil(response.error)) {
        console.error(response.error);
      }
    },
  });
};

const leaveUser = (params: IUserLeaveRequest) => {
  return request.post<number>('/users/leave', params);
};

export const useLeaveUser = ({ onSuccess }: { onSuccess: VoidFunction }) => {
  return useMutation({
    mutationFn: leaveUser,
    onSuccess: async response => {
      await removeToken();
      if (isNonNil(response.error)) {
        console.error(response.error);
      } else {
        onSuccess();
      }
    },
  });
};

const verifyUserLeaveAvailable = () => {
  return request.get<boolean>('/users/leave/verify');
};

export const useVerifyUserLeaveAvailable = ({
  onSuccess,
}: {
  onSuccess: (available: boolean) => void;
}) => {
  return useMutation({
    mutationFn: verifyUserLeaveAvailable,
    onSuccess: response => {
      if (isNonNil(response.error)) {
        console.error(response.error);
      }
      onSuccess(response.data);
    },
  });
};

const verifyUserName = (params: IUsernameVerifyRequest) => {
  return request.put<boolean>('/users/verify-username', params);
};

export const useVerifyUserName = ({
  onSuccess,
  onError,
}: {
  onSuccess: (isValid: boolean) => void;
  onError: (error?: IErrorResponse) => void;
}) => {
  return useMutation({
    mutationFn: verifyUserName,
    onSuccess: response => {
      if (isNonNil(response.error)) {
        onError(response.error);
      } else {
        onSuccess(response.data);
      }
    },
    onError: response => {
      onError(response.response?.data.error);
    },
    skipDefaultErrorHandling: error => true,
  });
};

const updateRandomProfileImage = () => {
  return request.patch<ApiResponseT<IMediaFileResponse>>(
    '/users/default-profile-image'
  );
};

export const useUpdateRandomProfileImage = ({
  onSuccess,
}: {
  onSuccess: VoidFunction;
}) => {
  return useMutation({
    mutationFn: updateRandomProfileImage,
    onSuccess: response => {
      if (isNonNil(response.error)) {
        console.error(response.error);
      }
      onSuccess?.();
    },
  });
};
