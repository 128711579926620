import { request } from '@/lib/request';
import { useInfiniteQuery, useQuery } from '@/lib/useWrappingQuery';
import {
  INotificationRequest,
  INotificationResponse,
  IPaginatedResponse,
} from '@/types';
import {
  notificationChannelsQueryOptions,
  notificationsQueryOptions,
} from './options';

export const useNotificationChannelsQuery = () => {
  return useQuery(notificationChannelsQueryOptions.list());
};

const searchNotifications = (params: INotificationRequest) => {
  return request.get<IPaginatedResponse<INotificationResponse>>(
    '/users/notifications',
    { params }
  );
};

export const notificationQueryPalyload = {
  size: 10,
};
export const useNotificationsQuery = ({
  params,
  options,
}: {
  params: Omit<INotificationRequest, 'size'>;
  options: {
    enabled?: boolean;
  };
}) => {
  const queryOptions = notificationsQueryOptions.list({
    payload: notificationQueryPalyload,
  });

  return useInfiniteQuery({
    queryKey: queryOptions.queryKey,
    queryFn: ({ pageParam: cursor }) => {
      return searchNotifications({
        ...params,
        ...notificationQueryPalyload,
        cursor,
      });
    },
    enabled: options.enabled ?? true,
  });
};
