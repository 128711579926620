import { RefObject, useEffect, useRef } from 'react';

export const useIntersectionObserver = (
  targetRef: RefObject<HTMLElement>,
  onIntersection: (params: {
    intersectionRatio: number;
    isOutside: boolean;
  }) => void,
  options?: IntersectionObserverInit
) => {
  const observerRef = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    const node = targetRef.current;
    if (!node) return;

    const observer = new IntersectionObserver(
      entries => {
        const intersectionRatio = entries[0].intersectionRatio;

        onIntersection({
          intersectionRatio,
          isOutside: intersectionRatio === 0,
        });
      },
      { rootMargin: '50%', ...options }
    );
    observer.observe(node);
    observerRef.current = observer;

    return () => {
      observer.disconnect();
      observerRef.current = null;
    };
  }, [targetRef, onIntersection]);

  return observerRef;
};
