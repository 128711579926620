'use client';

import { getRouterPath } from '@/components';
import { NavigationBar } from '@/components/navigation/NavigationBar';
import { useUserQuery } from '@/entities';
import { useLoginInducingModal } from '@/hooks/useLoginInducingModal';
import { Icon, IconButton, Row } from '@vuddy/components';
import { theme } from '@vuddy/components/theme';
import { useRouter } from 'next/navigation';

export const HomeNavigationBar = () => {
  const loginConfirm = useLoginInducingModal();
  const router = useRouter();
  const routerPath = getRouterPath({
    isAppWebView: false,
  });

  const { data: userData } = useUserQuery();

  const handleLoginRequiredIconClick = (pathname: string) => {
    if (userData?.data.isRegistered) {
      router.push(pathname);
    } else {
      loginConfirm(pathname);
    }
  };

  return (
    <NavigationBar
      title={
        <Icon
          name="ETC_VUDDYWORDMARK"
          width="90px"
          height={theme.size.image_xs}
        />
      }
      right={
        <Row display="flex" gap="gap_xl">
          <IconButton
            icon={{ name: 'FILL_NOTIFICATION_24' }}
            onClick={() => {
              handleLoginRequiredIconClick(routerPath.notification);
            }}
          />
          <IconButton
            icon={{ name: 'FILL_PERSON_24' }}
            onClick={() => {
              handleLoginRequiredIconClick(routerPath.my);
            }}
          />
        </Row>
      }
      backgroundColor="tertiary"
    />
  );
};
