export const getCdnUrl = (contentsUrl: string) => {
  return contentsUrl;
};

export const preloadImage = (imageUrl: string) => {
  return new Promise<void>(resolve => {
    const image = new Image();
    image.onload = () => resolve();
    image.src = imageUrl;
  });
};

export const preloadImages = (imageUrls: string[]) => {
  return Promise.all(imageUrls.map(preloadImage));
};
