'use client';

import { Carousel } from '@/components/carousel';
import { CarouselProps } from '@/components/carousel/Carousel';
import { useHomeHeadlinesQuery } from '@/entities';
import { Column, Element, IntegrationLink } from '@vuddy/components';
import { shadow, size } from '@vuddy/components/theme';
import { jsonAssignInlineVars } from '@vuddy/utils';
import Image from 'next/image';
import * as styles from './headline.css';

export const HomeHeadline = () => {
  const { data: headlineData } = useHomeHeadlinesQuery();
  const headlines = headlineData?.data ?? [];

  const settings: Omit<CarouselProps, 'children'> = {
    autoplay: true,
    autoplaySpeed: 3000,
    customPaging: (i: number) => {
      const oneSlidePageWidth = parseInt(size.image_6xl, 10) / headlines.length;

      return (
        <div
          className={styles.slidePaginationBarItem}
          style={jsonAssignInlineVars({
            [styles.slideItemLeft]: `${oneSlidePageWidth * i}px`,
            [styles.slideItemWidth]: `${parseInt(size.image_6xl, 10) / headlines.length}px`,
          })}
        />
      );
    },
  };

  return (
    <Column width="100%" marginTop="gap_xl" marginBottom="gap_6xl">
      <Carousel {...settings}>
        {headlines.map((slide, index) => (
          <div key={slide.title1}>
            <IntegrationLink
              isExternal={slide.landingUrl?.startsWith('http') ?? false}
              isLink={!!slide.landingUrl}
              link={slide.landingUrl!}>
              <Image
                src={slide.banner.url}
                width={1024} // 숫자가 낮으면 퀄리티값이 낮아짐. 그것 뺴곤 의미없는 숫자
                height={1024}
                style={{
                  aspectRatio: '328 / 280',
                  width: '100%',
                  height: '100%',
                  borderRadius: size.radius_large,
                  boxShadow: shadow.shadowHigh,
                }}
                alt={`home_headline_${index}`}
              />
            </IntegrationLink>
            <Column className={styles.contents}>
              <Element typography="titleSmall">{slide.title1}</Element>
              <Element typography="titleSmall">{slide.title2}</Element>
              <Element typography="captionBase">{slide.description}</Element>
            </Column>
          </div>
        ))}
      </Carousel>
    </Column>
  );
};
