'use client';

import { useHomeNoticesQuery } from '@/entities';
import { Ellipsis, Icon, IntegrationLink, Row } from '@vuddy/components';
import * as styles from './notice.css';

export const HomeNotice = () => {
  const { data: noticesData } = useHomeNoticesQuery();

  const notices = noticesData?.data ?? [];
  const isExternal = notices[0]?.landingUrl?.startsWith('http');

  return notices.length > 0 ? (
    <div className={styles.wrapper}>
      <IntegrationLink
        isExternal={isExternal!}
        isLink
        link={notices[0].landingUrl!}>
        <Row
          width="100%"
          alignItems="center"
          justifyContent="space-between"
          gap="gap_small"
          borderRadius="radius_base"
          backgroundColor="primary"
          paddingX="padding_xs"
          paddingY="padding_2xs">
          <Row className={styles.rowWrapper}>
            <Icon
              name="OUTLINE_NOTIFICATION_24"
              color="secondary"
              width="16px"
              height="16px"
            />
            <Ellipsis typography="captionBaseAccent" colorToken="primary">
              {notices[0].content}
            </Ellipsis>
          </Row>
          <Icon
            name="OUTLINE_CHEVRON_RIGHT_24"
            color="secondary"
            width="16px"
            height="16px"
          />
        </Row>
      </IntegrationLink>
    </div>
  ) : null;
};
