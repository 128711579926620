import { getRouterPath } from '@/components';
import { request } from '@/lib/request';
import { useMutation, useQuery } from '@/lib/useWrappingQuery';
import {
  IAuthUserLogoutRequest,
  IAuthUserRefreshRequest,
  IAuthUserRefreshResponse,
  IAuthenticateRequest,
  IAuthenticateResponse,
  IMeResponse,
  ISocialAuthUserExistsRequest,
  ISocialAuthUserExistsResponse,
  IUserSignUpStatusResponse,
} from '@/types';
import { getAccessTokenCookieName } from '@/utils/authToken';
import { getCookie, isNonNil } from '@vuddy/utils';
import { meOptions, userOptions, userSignUpStatusOptions } from './options';

/* 토큰 재발급 */
const refreshAuthUserToken = (params: IAuthUserRefreshRequest) => {
  return request.post<IAuthUserRefreshResponse>('/auth-users/refresh', params);
};

export const useRefreshAuthUserToken = ({
  onSuccess,
}: {
  onSuccess: VoidFunction;
}) => {
  return useMutation({
    mutationFn: refreshAuthUserToken,
    onSuccess: response => {
      if (isNonNil(response.error)) {
        console.error(response.error);
      } else {
        onSuccess();
      }
    },
  });
};

/* 소셜 계정 상태 조회 */
export const getAuthUserSocialProviderExists = (
  params: ISocialAuthUserExistsRequest
) => {
  return request.post<ISocialAuthUserExistsResponse>(
    '/auth-users/with-social-provider/exists',
    params
  );
};

export const useAuthUserSocialProviderExists = ({
  onSuccess,
}: {
  onSuccess: (
    data: ISocialAuthUserExistsResponse,
    variables: ISocialAuthUserExistsRequest
  ) => void;
}) => {
  return useMutation({
    mutationFn: getAuthUserSocialProviderExists,
    onSuccess: (response, variables) => {
      if (isNonNil(response.error)) {
        console.error(response.error);
      }
      onSuccess(response.data, variables);
    },
  });
};

/* 인증 */
export const authenticate = (params: IAuthenticateRequest) => {
  return request.post<IAuthenticateResponse>('/authenticate', params);
};

export const useAuthenticate = ({
  onSuccess,
}: {
  onSuccess: (data: IAuthenticateResponse) => void;
}) => {
  return useMutation({
    mutationFn: authenticate,
    onSuccess: response => {
      if (isNonNil(response.error)) {
        console.error(response.error);
      } else {
        onSuccess(response.data);
      }
    },
  });
};

/* 가입 상태 조회 */
export const getUserSignUpStatus = (headers?: Record<string, string>) => {
  return request.get<IUserSignUpStatusResponse>('/users/sign-up/status', {
    headers,
  });
};

export const useUserSignUpStatus = ({
  onSuccess,
}: {
  onSuccess: (data: IUserSignUpStatusResponse) => void;
}) => {
  return useMutation({
    mutationFn: getUserSignUpStatus,
    onSuccess: response => {
      if (isNonNil(response.error)) {
        console.error(response.error);
      }
      onSuccess(response.data);
    },
  });
};

export const useUserSignUpStatusQuery = ({
  refetchInterval,
  enabled,
}: {
  refetchInterval: number;
  enabled: boolean;
}) => {
  return useQuery({
    ...userSignUpStatusOptions.detail(),
    refetchInterval,
    enabled,
  });
};

/* 로그아웃 */
const userLogout = (params: IAuthUserLogoutRequest) => {
  return request.post<boolean>('/auth-users/logout', params);
};

export const useUserLogout = ({ onSuccess }: { onSuccess: VoidFunction }) => {
  const routerPath = getRouterPath({ isAppWebView: false });
  return useMutation({
    mutationFn: userLogout,
    onSuccess: response => {
      if (isNonNil(response.error)) {
        console.error(response.error);
      }
      onSuccess();
    },
  });
};

export const useUserQuery = () => {
  return useQuery({
    ...userOptions.detail(),
    enabled: isNonNil(getCookie(getAccessTokenCookieName())),
  });
};

/* 로그인한 사용자의 Me 정보 */
const getUserMe = () => {
  return request.get<IMeResponse>('/users/me');
};

export const useUserMe = () => {
  return useMutation({
    mutationFn: getUserMe,
    onSuccess: response => {
      if (isNonNil(response.error)) {
        console.error(response.error);
      }
    },
  });
};

export const useMeQuery = () => {
  return useQuery({
    ...meOptions.detail(),
    enabled: isNonNil(getCookie(getAccessTokenCookieName())),
  });
};
