import { request } from '@/lib/request';
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
} from '@/lib/useWrappingQuery';
import {
  IOrderHistorySearchRequest,
  IOrderHistorySearchResponse,
  IOrderItemCancelRequest,
  IOrderItemCancelResponse,
  IOrderItemPurchaseConfirmRequest,
  IPaginatedResponse,
  SortType,
} from '@/types';
import { isNonNil } from '@vuddy/utils';
import {
  ordersDetailCancelResultsQueryOptions,
  ordersDetailQueryOptions,
  ordersQueryOptions,
} from './options';

const searchOrders = (params: IOrderHistorySearchRequest) => {
  return request.post<IPaginatedResponse<IOrderHistorySearchResponse>>(
    '/orders/histories/_search',
    params
  );
};

export const ordersQueryPalyload = {
  sortType: 'ID_DESC' as SortType,
  size: 10,
};
export const useOrdersQuery = () => {
  const queryOptions = ordersQueryOptions.list({
    payload: ordersQueryPalyload,
  });

  return useInfiniteQuery({
    queryKey: queryOptions.queryKey,
    queryFn: ({ pageParam: cursor }) => {
      return searchOrders({
        ...ordersQueryPalyload,
        cursor,
      });
    },
  });
};

const orderPurchaseConfirm = (params: IOrderItemPurchaseConfirmRequest) => {
  return request.post<boolean>('/order-items/purchase-confirm', params);
};

export const useOrderPurchaseConfirmQuery = ({
  onSuccess,
}: {
  onSuccess: VoidFunction;
}) => {
  return useMutation({
    mutationFn: orderPurchaseConfirm,
    onSuccess: response => {
      if (isNonNil(response.error) || !response.data) {
        console.error(response.error);
      } else {
        onSuccess();
      }
    },
  });
};

const orderCancel = (params: IOrderItemCancelRequest) => {
  return request.post<IOrderItemCancelResponse>('/order-items/cancel', params);
};

export const useOrderCancelQuery = ({
  onSuccess,
}: {
  onSuccess: (data: IOrderItemCancelResponse) => void;
}) => {
  return useMutation({
    mutationFn: orderCancel,
    onSuccess: response => {
      if (isNonNil(response.error) || !response.data) {
        console.error(response.error);
      } else {
        onSuccess(response.data);
      }
    },
  });
};

export const useOrdersDetailCancelResultsQuery = ({
  orderItemCancelId,
  refetchInterval,
  enabled,
}: IOrderItemCancelResponse & {
  refetchInterval: number;
  enabled: boolean;
}) => {
  return useQuery({
    ...ordersDetailCancelResultsQueryOptions.one({
      payload: {
        orderItemCancelId,
      },
    }),
    refetchInterval,
    enabled,
  });
};

export const useOrdersDetailQuery = ({
  orderId,
  enabled,
}: {
  orderId: number;
  enabled: boolean;
}) => {
  return useQuery({
    ...ordersDetailQueryOptions.one({
      pathVariables: {
        orderId,
      },
    }),
    enabled,
  });
};
