import {
  IChannelDetailPageResponse,
  IChannelSimpleResponse,
  IOnBoardingChannelResponse,
  IPaginatedRequest,
  IPaginatedResponse,
  ISalePageSearchFilterResponse,
  ISalePageSearchRequest,
  ISalePageSearchResponse,
} from '@/types';
import { createQueryOption } from 'react-query-key-helper';
import { request } from '../../lib/request';

export const channelDetailsQueryOption = createQueryOption({
  apiUrl: () => '/channels/detail',
  queryFn: (apiUrl: string, params: { url: string }) =>
    request.get<IChannelDetailPageResponse>(apiUrl, { params }),
});

export const channelDetailsByIdQueryOption = createQueryOption({
  apiUrl: (channelId: number | undefined) => `/channels/${channelId}/detail`,
  queryFn: (apiUrl: string) => request.get<IChannelDetailPageResponse>(apiUrl),
});

export const channelSalePageSearchFilterQueryOption = createQueryOption({
  apiUrl: (channelId: number) =>
    `/channels/${channelId}/sale-pages/search-filters`,
  queryFn: apiUrl => request.get<ISalePageSearchFilterResponse[]>(apiUrl),
});

export const channelSalePageQueryOptions = {
  list: createQueryOption({
    apiUrl: (channelId: number) => `/channels/${channelId}/sale-pages/_search`,
    queryFn: (apiUrl: string, payload: ISalePageSearchRequest) =>
      request.post<IPaginatedResponse<ISalePageSearchResponse>>(
        apiUrl,
        payload
      ),
  }),
};

export const channelFollowStatusQueryOption = createQueryOption({
  apiUrl: (id: number | undefined) => `/channels/${id}/follow`,
  queryFn: apiUrl => request.get<boolean>(apiUrl),
});

export const channelFollowerCountQueryOption = createQueryOption({
  apiUrl: (id: number | undefined) => `/channels/${id}/followers/count`,
  queryFn: apiUrl => request.get<number>(apiUrl),
});

export const onBoardingChannelsQueryOptions = {
  list: createQueryOption({
    apiUrl: () => '/onboarding/channels/_search',
    queryFn: (apiUrl: string, params: IPaginatedRequest) =>
      request.post<IPaginatedResponse<IOnBoardingChannelResponse>>(apiUrl, {
        params,
      }),
  }),
};

export const channelSimpleQueryOptions = createQueryOption({
  apiUrl: (salePageId: number) => `/sale-pages/${salePageId}/channels`,
  queryFn: apiUrl => request.get<IChannelSimpleResponse>(apiUrl),
});
