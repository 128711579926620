import { request } from '@/lib/request';
import {
  IOrderDetailResponse,
  IOrderHistorySearchRequest,
  IOrderHistorySearchResponse,
  IOrderItemCancelResponse,
  IOrderItemCancelResultResponse,
} from '@/types';
import { createQueryOption } from 'react-query-key-helper';

export const ordersQueryOptions = {
  list: createQueryOption({
    apiUrl: () => '/orders/histories/_search',
    queryFn: (apiUrl: string, payload: IOrderHistorySearchRequest) =>
      request.post<IOrderHistorySearchResponse[]>(apiUrl, payload),
  }),
};

export const ordersDetailCancelResultsQueryOptions = {
  one: createQueryOption({
    apiUrl: () => `/order-items/cancel-results`,
    queryFn: (
      apiUrl: string,
      { orderItemCancelId }: IOrderItemCancelResponse
    ) =>
      request.get<IOrderItemCancelResultResponse>(apiUrl, {
        params: { orderItemCancelId },
      }),
  }),
};

export const ordersDetailQueryOptions = {
  one: createQueryOption({
    apiUrl: (pathVariables: { orderId: number }) =>
      `/orders/${pathVariables.orderId}`,
    queryFn: (apiUrl: string) => request.get<IOrderDetailResponse>(apiUrl),
  }),
};
