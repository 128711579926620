import { request } from '@/lib/request';
import { IDeliveryAddressResponse } from '@/types';
import { createQueryOption } from 'react-query-key-helper';

export const deliveryQueryOptions = {
  list: createQueryOption({
    apiUrl: () => '/users/delivery-address',
    queryFn: (apiUrl: string) =>
      request.get<IDeliveryAddressResponse[]>(apiUrl),
  }),
  one: createQueryOption({
    apiUrl: (deliveryId: number | undefined) =>
      `/users/delivery-address/${deliveryId}`,
    queryFn: (apiUrl: string) => request.get<IDeliveryAddressResponse>(apiUrl),
  }),
};
