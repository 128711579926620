'use client';

import { IHomeChannelSearchResponse } from '@/types/home';
import { Grid, LoadingSpinner } from '@vuddy/components';
import { forwardRef } from 'react';
import { ChannelCardItem } from './ChannelCardItem';

interface ChannelGridProps {
  channels: IHomeChannelSearchResponse[];
  onClickItem?: (url: string) => void;
  isSelectedItem?: (url: string) => boolean;
  showLoadingObserver?: boolean;
}

export const ChannelGrid = forwardRef<HTMLDivElement, ChannelGridProps>(
  (
    { channels, showLoadingObserver = false, isSelectedItem, onClickItem },
    ref
  ) => {
    return (
      <>
        <Grid repeat={2} gap="gap_xl">
          {channels.map(({ channel, isNewbie }) => (
            <ChannelCardItem
              key={channel.id}
              channel={channel}
              isNewbie={isNewbie}
              isSelectedItem={isSelectedItem?.(channel.url)}
              onClick={() => onClickItem?.(channel.url)}
            />
          ))}
        </Grid>
        {showLoadingObserver ? <LoadingSpinner ref={ref} /> : null}
      </>
    );
  }
);
