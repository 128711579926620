import { request } from '@/lib/request';
import { useInfiniteQuery, useQuery } from '@/lib/useWrappingQuery';
import { IPaginatedRequest, IPaginatedResponse, SortType } from '@/types';
import { IHomeChannelSearchResponse } from '@/types/home';
import { getAccessTokenCookieName } from '@/utils/authToken';
import { getCookie, isNonNil } from '@vuddy/utils';
import {
  homeChannelsQueryOptions,
  homeFollowingChannelsQueryOptions,
  homeHeadlinesQueryOptions,
  homeNoticesQueryOptions,
} from './options';

export const useHomeNoticesQuery = () => {
  return useQuery(homeNoticesQueryOptions.list());
};

export const useHomeHeadlinesQuery = () => {
  return useQuery(homeHeadlinesQueryOptions.list());
};

export const useHomeFollowingChannelsQuery = () => {
  return useQuery({
    ...homeFollowingChannelsQueryOptions.list(),
    enabled: isNonNil(getCookie(getAccessTokenCookieName())),
  });
};

const searchHomeChannels = (params: Omit<IPaginatedRequest, 'sortType'>) =>
  request.post<IPaginatedResponse<IHomeChannelSearchResponse>>(
    '/home/channels/_search',
    {
      ...params,
      sortType: 'DISPLAY_SCORE_DESC',
    }
  );

export const homeChannelsQueryPalyload = {
  sortType: 'DISPLAY_SCORE_DESC' as SortType,
  size: 10,
};
export const useHomeChannelsQuery = () => {
  const queryOptions = homeChannelsQueryOptions.list({
    payload: homeChannelsQueryPalyload,
  });

  return useInfiniteQuery({
    queryKey: queryOptions.queryKey,
    queryFn: ({ pageParam: cursor }) => {
      return searchHomeChannels({
        ...homeChannelsQueryPalyload,
        cursor,
      });
    },
  });
};
