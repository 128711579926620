import { mediaRequest } from '@/lib/request';
import { useMutation } from '@/lib/useWrappingQuery';
import { ApiResponseT } from '@/types';
import { IImageFileUploadRequest, IImageResponse } from '@/types/media';

const uploadImageFile = (params: IImageFileUploadRequest) => {
  return mediaRequest.post<ApiResponseT<IImageResponse>>('/image', params);
};

export const useUploadImageFileQuery = ({
  onSuccess,
}: {
  onSuccess: (data: IImageResponse) => void;
}) => {
  return useMutation({
    mutationFn: uploadImageFile,
    onSuccess: response => {
      onSuccess(response.data);
    },
  });
};
