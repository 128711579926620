'use client';

import { IHomeChannelResponse } from '@/types/home';
import { getCdnUrl } from '@/utils/image';
import { Column, ElementProps, Ellipsis } from '@vuddy/components';
import { SizeKey, line, theme } from '@vuddy/components/theme';
import { jsonAssignInlineVars } from '@vuddy/utils';
import Image from 'next/image';
import { useState } from 'react';
import * as styles from './channelItem.css';

interface IChannelItemProps extends Pick<ElementProps, 'height' | 'gap'> {
  channel: IHomeChannelResponse;
  padding?: string;
  imageSize?: SizeKey;
  nameLineClamp?: number;
  othersLineClamp?: number;
  showTags?: boolean;
}

export const ChannelItem = ({
  channel,
  height,
  gap = 'gap_small',
  padding,
  imageSize = 'image_3xl',
  nameLineClamp = 1,
  othersLineClamp = 1,
  showTags = false,
}: IChannelItemProps) => {
  const [isError, setIsError] = useState(false);

  const style = {
    width: theme.size[imageSize],
    height: theme.size[imageSize],
    borderRadius: '50%',
    border: `1px solid ${line.imgOutline}`,
  };

  return (
    <Column
      key={channel.id}
      gap={gap}
      alignItems="center"
      justifyContent="center"
      height={height}>
      <div
        className={styles.imageWrapper}
        style={jsonAssignInlineVars({
          [styles.imageWrapperPadding]: padding,
        })}>
        {channel.thumbnail && !isError ? (
          <Image
            src={channel.thumbnail?.url}
            width={1024}
            height={1024}
            quality={100}
            onError={() => setIsError(true)}
            style={style}
            alt={`${channel.id}`}
          />
        ) : (
          <Image
            src={getCdnUrl('/images/user_random_profile.png')}
            width={1024}
            height={1024}
            quality={100}
            style={style}
            alt="user random profile image"
          />
        )}
      </div>
      <Column gap="gap_xs" alignItems="center">
        <Ellipsis
          lineClamp={nameLineClamp}
          typography="bodyXsAccent"
          textAlign="center">
          {channel.name}
        </Ellipsis>
        <Column alignItems="center">
          {showTags && channel.tags.length > 0
            ? channel.tags.slice(0, 2).map(tag => (
                <Ellipsis
                  key={tag}
                  lineClamp={othersLineClamp}
                  typography="captionBase"
                  colorToken="secondary">
                  {tag}
                </Ellipsis>
              ))
            : null}
        </Column>
      </Column>
    </Column>
  );
};
