import { Children, ComponentProps, Fragment, ReactNode } from 'react';
import Slider from 'react-slick';

type SliderProps = ComponentProps<typeof Slider>;

export type CarouselProps = SliderProps & {
  children: ReactNode;
  onChange?: (index: number) => void;
};

export const Carousel = ({
  children,
  onChange,
  customPaging = () => <></>,
  ...slideProps
}: CarouselProps) => {
  const childrenArray = Children.toArray(children);

  const settings: SliderProps = {
    dots: true,
    infinite: childrenArray.length > 1,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    centerMode: false,
    autoplay: false,
    autoplaySpeed: 8000,
    speed: 800,
    pauseOnDotsHover: true,
    touchMove: true,
    swipe: childrenArray.length > 1,
    dotsClass: 'slick-pagination-bar',
    customPaging,
    beforeChange: (_, newIndex) => {
      onChange?.(newIndex);
    },
    ...slideProps,
  };

  return (
    <Slider {...settings}>
      {childrenArray.map((child, index) => (
        <Fragment key={index}>{child}</Fragment>
      ))}
    </Slider>
  );
};
