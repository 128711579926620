import { request } from '@/lib/request';
import { IMeResponse, IUserResponse, IUserSignUpStatusResponse } from '@/types';
import { createQueryOption } from 'react-query-key-helper';

export const userOptions = {
  detail: createQueryOption({
    apiUrl: () => '/users',
    queryFn: (apiUrl: string) => request.get<IUserResponse>(apiUrl),
  }),
};

export const meOptions = {
  detail: createQueryOption({
    apiUrl: () => '/users/me',
    queryFn: (apiUrl: string) => request.get<IMeResponse>(apiUrl),
  }),
};

export const userSignUpStatusOptions = {
  detail: createQueryOption({
    apiUrl: () => '/users/sign-up/status',
    queryFn: (apiUrl: string) => request.get<IUserSignUpStatusResponse>(apiUrl),
  }),
};
